import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Input, Skeleton as AntdSkeleton } from 'antd'

export const Container = styled('div')`
  border-top: 1px solid #e5e5e5;
  padding-bottom: 16px;
`

export const Top = styled('div')`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const Bottom = styled('div')`
  display: flex;
  margin-bottom: 16px;
`

export const Mid = styled('div')`
  display: flex;
`

export const MidContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const NbContainer = styled('div')`
  border-radius: 50%;
  background-color: #e6f6ea;
  width: 28px;
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const NumberBadge = styled('div')`
  color: #009640;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
`

export const TContainer = styled('div')``

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  color: #000000;
`

export const SubTitle = styled('div')`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`

export const TabContainer = styled('div')`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
`

export const Tab = styled('div')<{ active: boolean }>`
  flex: 1;
  padding: 8px 16px;
  width: 130px;
  text-align: center;
  color: ${({ active }) => (active ? '#000' : '#666')};
  background-color: ${({ active }) => (active ? '#F6F6F6' : 'transparent')};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:last-of-type) {
    border-right: 1px solid #e0e0e0;
  }
`

export const Badge = styled('span')`
  background-color: #e9ebef;
  color: #666;
  border-radius: 12px;
  padding: 2px 8px;
  margin-left: 8px;
  font-size: 12px;
`

export const SearchInput = styled(Input)`
  width: 690px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  max-width: 320px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const NotFoundContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 64px;
`

export const NotFoundIcon = styled('div')`
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-bottom: 8px;
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled('img')`
  width: 20px;
  height: 20px;
`

export const NotFoundText = styled('div')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`
