import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { useQuery } from '@tanstack/react-query'
import { TviConnectCheckStatusInputType, TviConnectCheckStatusResponseType } from './types'

const fetchTviConnectCheckStatus = async (query: TviConnectCheckStatusInputType) => {
  const response = await httpClientPrivate.post<TviConnectCheckStatusResponseType[]>(
    `${config.restUrl}/v1/backoffice-ppu-motor/tvi-connect/check-status`,
    query,
  )
  return response.data
}

const useTviConnectCheckStatus = (query: TviConnectCheckStatusInputType) => {
  const queryKey = ['useTviConnectCheckStatus', query]
  const queryFn = async () => fetchTviConnectCheckStatus(query)
  const queryResult = useQuery({ queryKey, queryFn, enabled: query.deviceIds.length > 0 })
  return { ...queryResult }
}

export { useTviConnectCheckStatus }
