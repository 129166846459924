import { useEffect, useState } from 'react'
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs'
import { TviConnectDeviceType } from '../types'
import { FilterEnum } from './types'

const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const CheckTviConnectSectionHook = ({
  setSearchDevice,
  devices,
}: {
  setSearchDevice: (devices: TviConnectDeviceType[]) => void
  devices: TviConnectDeviceType[]
}) => {
  const [selectedFilter, setSelectedFilter] = useState(FilterEnum.ALL)
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    inputChange.next(value)
  }

  const onFilterData = (type: FilterEnum) => {
    setSelectedFilter(type)

    if (type === FilterEnum.ALL) {
      setSearchDevice(devices)
      return
    }

    const filteredData = devices.filter((item) => {
      if (type === FilterEnum.PENDING) {
        return !item.off || !item.on
      }
      return item.off && item.on
    })
    setSearchDevice(filteredData)
  }

  useEffect(() => {
    const subscription = inputChange$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) {
          setSearchDevice(devices)
          return
        }

        const filteredData = devices.filter((item) => {
          return item.imei.includes(value) || item.serialNumber.includes(value)
        })
        setSearchDevice(filteredData)
      })

    return () => {
      inputChange.next('')
      return subscription.unsubscribe()
    }
  }, [devices, setSearchDevice])

  return { onSearch, onFilterData, selectedFilter }
}

export default CheckTviConnectSectionHook
