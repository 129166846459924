import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import CONSTANT from '@/constant'
import notificationAtom from '@/recoil/notification'
import { useLazyQuery } from '@apollo/client'
import { activeHealthPolicyGql, userGql } from '@/gql'
import { UserHelpSearchableFieldEnum, UserHelpStatusEnum } from '@/constant/USER'
import {
  BACKOFFICE_USER_MENU,
  BACKOFFICE_USER_MENU_TYPE,
  BACKOFFICE_USER_PERMISSION,
} from '@/constant/BACKOFFICE_PERMISSION'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { IMAGE_URL } from '@/constant/IMAGE'

function SideBarHook() {
  const user = useRecoilValue(userAtom)
  const [notification, setNotification] = useRecoilState(notificationAtom)
  const location = useLocation()
  const [getUserHelp, { data: userHelpData }] = useLazyQuery(
    userGql.query.backofficeGetUserHelpPagination,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {},
      },
    },
  )
  const [getSuccessfulUserHelp, { data: successfulUserHelpData }] = useLazyQuery(
    userGql.query.backofficeGetUserHelpPagination,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          searchBy: [UserHelpSearchableFieldEnum.STATUS],
          search: UserHelpStatusEnum.SUCCESSFUL,
        },
      },
    },
  )
  const [countPendingActiveHealthBillingDiscountProblem] = useLazyQuery(
    activeHealthPolicyGql.query.backofficeCountPendingActiveHealthBillingDiscountProblemRecord,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const count = data.backofficerCountPendingActiveHealthBillingDiscountProblemRecord

        setNotification({
          ...notification,
          discountIssuePage: {
            count,
          },
        })
      },
    },
  )
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const menus: Array<{
    title: string
    hasBottomLine: boolean
    display: boolean
    subMenus: Array<{
      key: string
      iconSource: string
      to: string
      title: string
      display: boolean
      toExternal?: boolean
      id: string
      notification?: {
        notify?: boolean
        count?: number
      }
    }>
  }> = [
    {
      title: '',
      hasBottomLine: true,
      display: validatePerm(
        [
          BACKOFFICE_USER_MENU_TYPE['ข้อมูลลูกค้าทั่วไป'],
          BACKOFFICE_USER_MENU_TYPE['ประกันรถเปิดปิด'],
        ],
        user.menuTypes,
      ),
      subMenus: [
        {
          key: CONSTANT.ROUTES.POLICY_DETAIL_PAGE,
          iconSource: IMAGE_URL.userManagementIcon,
          to: CONSTANT.ROUTES.POLICY_DETAIL_PAGE,
          title: 'ข้อมูลลูกค้าทั่วไป',
          id: 'menu-policy-detail',
          display: validatePerm(
            [BACKOFFICE_USER_MENU.CUS01, BACKOFFICE_USER_MENU.MTP01],
            user.menus,
          ),
        },
        {
          key: CONSTANT.ROUTES.DOPA_PAGE,
          iconSource: IMAGE_URL.personCardIcon,
          to: CONSTANT.ROUTES.DOPA_PAGE,
          title: 'DOPA',
          id: 'menu-dopa',
          display: validatePerm([BACKOFFICE_USER_MENU.DOP01], user.menus),
        },
      ],
    },
    {
      title: 'ประกันรถเปิด-ปิด',
      hasBottomLine: true,
      display:
        (validatePerm([BACKOFFICE_USER_MENU.MTD01], user.menus) ||
          validatePerm([BACKOFFICE_USER_MENU.MTT01], user.menus)) &&
        validatePerm([BACKOFFICE_USER_MENU_TYPE['ประกันรถเปิดปิด']], user.menuTypes),
      subMenus: [
        {
          key: CONSTANT.ROUTES.TVI_CONNECT_PAGE,
          iconSource: IMAGE_URL.tviConnectIcon,
          to: CONSTANT.ROUTES.TVI_CONNECT_PAGE,
          title: 'TVI-Connect',
          id: 'menu-tvi-connect',
          display: validatePerm([BACKOFFICE_USER_MENU.MTD01], user.menus),
        },
        {
          key: '3',
          iconSource: IMAGE_URL.motorTrackerIcon,
          to: CONSTANT.SIDE_BAR.MOTOR_TRACKER_URL,
          title: 'Motor Tracker',
          id: 'menu-motor-tracker',
          display: validatePerm([BACKOFFICE_USER_MENU.MTT01], user.menus),
          toExternal: true,
        },
        {
          key: CONSTANT.ROUTES.EV_CHARGING_STATION_PAGE,
          iconSource: IMAGE_URL.evChargingStationIcon,
          to: CONSTANT.ROUTES.EV_CHARGING_STATION_PAGE,
          title: 'สถานีชาร์จรถไฟฟ้า',
          id: 'menu-map-ev-charging-station',
          display: validatePerm([BACKOFFICE_USER_MENU.MTD01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.RETURN_TIME_PAGE,
          iconSource: IMAGE_URL.returnTimeIcon,
          to: CONSTANT.ROUTES.RETURN_TIME_PAGE,
          title: 'คืนชั่วโมงความคุ้มครอง',
          id: 'menu-return-time',
          display: validatePerm([BACKOFFICE_USER_MENU.MTA01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.TVI_CONNECT_CHECK_PAGE,
          iconSource: IMAGE_URL.checkTviConnectIcon,
          to: CONSTANT.ROUTES.TVI_CONNECT_CHECK_PAGE,
          title: 'ตรวจสอบอุปกรณ์ TVI Connect',
          id: 'menu-check-tvi-connect',
          display: validatePerm([BACKOFFICE_USER_MENU.MTD01], user.menus), // TODO: add permission for this page
        },
      ],
    },
    {
      title: 'ประกันสุขภาพ',
      hasBottomLine: true,
      display: validatePerm([BACKOFFICE_USER_MENU_TYPE['ประกันสุขภาพ']], user.menuTypes),
      subMenus: [
        {
          key: CONSTANT.ROUTES.WELCOME_CALL_PAGE,
          iconSource: IMAGE_URL.welcomeCallIcon,
          to: CONSTANT.ROUTES.WELCOME_CALL_PAGE,
          title: 'Welcome call',
          id: 'menu-welcome-call',
          display: validatePerm([BACKOFFICE_USER_MENU.WEL01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE,
          iconSource: IMAGE_URL.policyManagementIcon,
          to: CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE,
          title: 'จัดการกรมธรรม์',
          id: 'menu-policy-management',
          display: validatePerm([BACKOFFICE_USER_MENU.POL01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE,
          iconSource: IMAGE_URL.discountIssueIcon,
          to: CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE,
          title: 'รายการส่วนลดที่มีปัญหา',
          id: 'menu-discount-issue',
          display: validatePerm([BACKOFFICE_USER_MENU.DIS01], user.menus),
          notification: {
            notify: notification.discountIssuePage.count > 0,
            count: notification.discountIssuePage.count,
          },
        },
        {
          key: CONSTANT.ROUTES.WATCH_MANAGEMENT_PAGE,
          iconSource: IMAGE_URL.watchManagementIcon,
          to: CONSTANT.ROUTES.WATCH_MANAGEMENT_PAGE,
          title: 'จัดการนาฬิกา',
          id: 'menu-watch-management',
          display: validatePerm([BACKOFFICE_USER_MENU.WAT01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.CAMPAIGN_MANAGEMENT_PAGE,
          iconSource: IMAGE_URL.campaignManagementIcon,
          to: CONSTANT.ROUTES.CAMPAIGN_MANAGEMENT_PAGE,
          title: 'จัดการ Campaign',
          id: 'menu-campaign-management',
          display: validatePerm([BACKOFFICE_USER_MENU.CAM01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.HELP_PAGE,
          iconSource: IMAGE_URL.helpIcon,
          to: CONSTANT.ROUTES.HELP_PAGE,
          title: 'ช่วยเหลือ',
          id: 'menu-help',
          display: validatePerm([BACKOFFICE_USER_MENU.HEL01], user.menus),
          notification: {
            notify: notification.helpPage.count > 0,
            count: notification.helpPage.count,
          },
        },
        {
          key: `/${CONSTANT.PATHNAME.WELL_BEING}`,
          iconSource: IMAGE_URL.wellBeingOutlinedIcon,
          to: `/${CONSTANT.PATHNAME.WELL_BEING}`,
          title: 'Well-Being',
          id: 'menu-partner-well-being',
          display: validatePerm([BACKOFFICE_USER_MENU.WBE01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.FREQUENCY_QUESTIONS_PAGE,
          iconSource: IMAGE_URL.faqIcon,
          to: CONSTANT.ROUTES.FREQUENCY_QUESTIONS_PAGE,
          title: 'คำถามที่พบบ่อย',
          id: 'menu-frequency-questions',
          display: validatePerm([BACKOFFICE_USER_MENU.FAQ01], user.menus),
        },
      ],
    },
    {
      title: 'ประกันเดินทาง',
      hasBottomLine: true,
      display: validatePerm([BACKOFFICE_USER_MENU_TYPE['ประกันเดินทาง']], user.menuTypes),
      subMenus: [
        {
          key: CONSTANT.ROUTES.TA_TRANSACTION_PAGE,
          iconSource: IMAGE_URL.travelMenuIcon,
          to: CONSTANT.ROUTES.TA_TRANSACTION_PAGE,
          title: 'รายการสั่งซื้อประกันภัยการเดินทาง',
          id: 'menu-ta-transaction',
          display: validatePerm([BACKOFFICE_USER_MENU.TA01], user.menus),
        },
      ],
    },
    {
      title: 'การจัดการระบบ',
      hasBottomLine: false,
      display: validatePerm([BACKOFFICE_USER_MENU_TYPE['การจัดการระบบ']], user.menuTypes),
      subMenus: [
        {
          key: CONSTANT.ROUTES.USER_PERMISSION_PAGE,
          iconSource: IMAGE_URL.rolesManagementIcon,
          to: CONSTANT.ROUTES.USER_PERMISSION_PAGE,
          title: 'การจัดการสิทธิและบทบาทผู้ใช้งาน',
          id: 'menu-user-permission',
          display: validatePerm([BACKOFFICE_USER_MENU.USR01], user.menus),
        },
        {
          key: CONSTANT.ROUTES.REPORT_PAGE,
          iconSource: IMAGE_URL.reportIcon,
          to: CONSTANT.ROUTES.REPORT_PAGE,
          title: 'รายงาน',
          display: true,
          id: 'menu-report',
        },
      ],
    },
  ]
  const userManagementRoutes = [
    CONSTANT.ROUTES.POLICY_DETAIL_PAGE,
    CONSTANT.ROUTES.TVI_CONNECT_PAGE,
    CONSTANT.ROUTES.REPORT_PAGE,
    CONSTANT.ROUTES.WELCOME_CALL_PAGE,
    CONSTANT.ROUTES.USER_PERMISSION_PAGE,
    CONSTANT.ROUTES.WATCH_MANAGEMENT_PAGE,
    CONSTANT.ROUTES.CAMPAIGN_MANAGEMENT_PAGE,
    CONSTANT.ROUTES.FREQUENCY_QUESTIONS_PAGE,
    CONSTANT.ROUTES.HELP_PAGE,
    CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE,
    CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE,
    CONSTANT.ROUTES.WELL_BEING_PAGE,
    CONSTANT.ROUTES.EV_CHARGING_STATION_PAGE,
    CONSTANT.ROUTES.TA_TRANSACTION_PAGE,
    CONSTANT.ROUTES.RETURN_TIME_PAGE,
    CONSTANT.ROUTES.DOPA_PAGE,
    CONSTANT.ROUTES.TVI_CONNECT_CHECK_PAGE,
  ]

  useEffect(() => {
    if (userManagementRoutes.includes(location.pathname)) {
      setSelectedKeys([location.pathname])
    } else if (
      location?.state?.referrer &&
      userManagementRoutes.includes(location?.state?.referrer)
    ) {
      setSelectedKeys([location.state.referrer])
    } else {
      setSelectedKeys([CONSTANT.ROUTES.POLICY_DETAIL_PAGE])
    }
  }, [location])

  useEffect(() => {
    if (
      userHelpData?.backofficeGetUserHelpPagination &&
      successfulUserHelpData?.backofficeGetUserHelpPagination
    ) {
      const totalSuccessful = successfulUserHelpData.backofficeGetUserHelpPagination.meta.totalItems
      const totalItems = userHelpData.backofficeGetUserHelpPagination.meta.totalItems
      const count = totalItems - totalSuccessful

      setNotification({
        ...notification,
        helpPage: {
          count,
        },
      })
    }
  }, [successfulUserHelpData, userHelpData])

  useEffect(() => {
    if (validatePerm([BACKOFFICE_USER_PERMISSION.HEL010101], user.permissions)) {
      getUserHelp()
      getSuccessfulUserHelp()
    }

    if (validatePerm([BACKOFFICE_USER_PERMISSION.DIS010101], user.permissions)) {
      countPendingActiveHealthBillingDiscountProblem()
    }
  }, [])

  return {
    selectedKeys,
    menus,
    setSelectedKeys,
  }
}

export default SideBarHook
