import { useEffect, useState } from 'react'
import { InteractionStatus } from '@azure/msal-browser'

import { UserAuthHook } from './hooks'

const AppHook = () => {
  const [isBootstrapping, setIsBootstrapping] = useState(true)
  const { inProgress, backofficeLoading, isInitializing } = UserAuthHook()

  useEffect(() => {
    const shouldBootstrap =
      inProgress === InteractionStatus.None && !backofficeLoading && !isInitializing
    if (shouldBootstrap) {
      bootstrapApp()
    } else {
      loadingBootstrapApp()
    }
  }, [inProgress, backofficeLoading, isInitializing])

  const bootstrapApp = () => {
    setIsBootstrapping(false)
  }

  const loadingBootstrapApp = () => {
    setIsBootstrapping(true)
  }

  return {
    isBootstrapping,
  }
}

export default AppHook
