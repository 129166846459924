import { useCallback, useEffect, useState } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useRecoilState } from 'recoil'

import { acquireToken } from '@/providers/msal.helper'
import { msalInstance } from '@/providers/msal'
import { backofficeUserGql } from '@/gql'
import userAtom from '@/recoil/user'
import CONSTANT from '@/constant'

const UserAuthHook = () => {
  const navigate = useNavigate()
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [, setUser] = useRecoilState(userAtom)
  const [isInitializing, setIsInitializing] = useState(true)

  const [
    backofficeGetMe,
    { data: backofficeGetMeData, loading: backofficeLoading, error: backofficeGetMeError },
  ] = useLazyQuery(backofficeUserGql.query.backofficeGetMe, { fetchPolicy: 'no-cache' })

  const handleRefreshTokenAzureAD = useCallback(async () => {
    const account = msalInstance.getActiveAccount()

    if (!account) {
      return
    }

    const loginRequest = { scopes: ['openid', 'User.Read'], account }
    const idToken = await acquireToken(loginRequest)

    if (idToken) {
      // fetch user
      await backofficeGetMe()

      // redirect to home if already on login page
      if (window.location.pathname === CONSTANT.ROUTES.LOGIN_PAGE) {
        navigate('/')
      }
    } else {
      // clear session and redirect to login page if token is not acquired
      localStorage.clear()
      msalInstance.clearCache()
      navigate(CONSTANT.ROUTES.LOGIN_PAGE)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backofficeGetMe])

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const fetchToken = async () => {
        await handleRefreshTokenAzureAD()
        setIsInitializing(false)
      }

      fetchToken()
    }
  }, [inProgress, handleRefreshTokenAzureAD])

  useEffect(() => {
    if (isAuthenticated && backofficeGetMeData && backofficeGetMeData.backofficeGetMe) {
      const {
        email,
        firstname,
        lastname,
        id,
        menus,
        menuTypes,
        pages,
        permissions,
        backofficeTeams,
      } = backofficeGetMeData.backofficeGetMe
      setUser({
        accessToken: localStorage.getItem('accessToken'),
        email,
        firstname,
        id,
        lastname,
        menuTypes,
        menus,
        pages,
        permissions,
        staffId: id,
        isAuth: isAuthenticated,
        backofficeTeams,
      })
    }
  }, [isAuthenticated, backofficeGetMeData, setUser])

  useEffect(() => {
    if (backofficeGetMeError) {
      const userNotFound = backofficeGetMeError.graphQLErrors?.some(
        ({ extensions }) => extensions?.code === 'BIZBOUAUTH1004',
      )
      if (!userNotFound) {
        navigate(CONSTANT.PATHNAME.LOGIN)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backofficeGetMeError])

  return {
    inProgress,
    backofficeLoading,
    isInitializing,
  }
}

export default UserAuthHook
