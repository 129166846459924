import { useEffect, useState } from 'react'
import Papa from 'papaparse'
import { UploadProps } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { ImportTviConnectCheckDeviceInputType } from '@/services/tviConnect/useImportTviConnectCheckDevice/types'

const AssignTviConnectInfoHook = ({
  submitImportDevice,
}: {
  submitImportDevice: (data: ImportTviConnectCheckDeviceInputType[]) => Promise<void>
}) => {
  const UPLOAD = 1
  const MANUAL = 2
  const [option, setOption] = useState<typeof UPLOAD | typeof MANUAL>(UPLOAD)
  const [parsedData, setParsedData] = useState<ImportTviConnectCheckDeviceInputType[]>([])
  const [imeiInput, setImeiInput] = useState<string>('')
  const [serialNumberInput, setSerialNumberInput] = useState<string>('')

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    beforeUpload: (file) => {
      handleFileUpload(file)

      return false
    },
  }

  const handleFileUpload = (file: RcFile) => {
    if (file) {
      Papa.parse<ImportTviConnectCheckDeviceInputType>(file, {
        header: true,
        complete: (result) => {
          const mappedData = result.data
            .map((item) => ({
              imei: item.imei,
              serialNumber: item.serialNumber,
              eSimNumber: item.eSimNumber,
            }))
            .filter((item) => item.imei && item.serialNumber)

          setParsedData(mappedData)
        },
      })
    }
  }

  const submitInputDevice = () => {
    if (imeiInput && serialNumberInput) {
      const newDevice = {
        imei: imeiInput,
        serialNumber: serialNumberInput,
        eSimNumber: '',
      }

      setParsedData([newDevice])
      setImeiInput('')
      setSerialNumberInput('')
    }
  }

  useEffect(() => {
    if (parsedData.length > 0) {
      submitImportDevice(parsedData)
    }
  }, [parsedData])

  return {
    option,
    setOption,
    UPLOAD,
    MANUAL,
    handleFileUpload,
    uploadProps,
    setImeiInput,
    setSerialNumberInput,
    imeiInput,
    serialNumberInput,
    submitInputDevice,
  }
}

export default AssignTviConnectInfoHook
