import { createContext } from 'react'
import type { ContextTypes } from './types'

const defaultValue: ContextTypes = {
  login: async () => undefined,
  logout: (navigateTo?: string) => undefined,
}

const UserAuthContext = createContext(defaultValue)

export default UserAuthContext
