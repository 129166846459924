import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { useMutation } from '@tanstack/react-query'
import {
  UnregisterTviConnectDeviceInputType,
  UnregisterTviConnectDeviceResponseType,
} from './types'

const unregisterTviConnectDevice = async (data: UnregisterTviConnectDeviceInputType[]) => {
  const response = await httpClientPrivate.delete<UnregisterTviConnectDeviceResponseType[]>(
    `${config.restUrl}/v1/backoffice-ppu-motor/unregister-tvi-connect-devices`,
    { data: { input: data } },
  )
  return response.data
}

const useUnregisterTviConnectDevice = () => {
  return useMutation({
    mutationKey: ['useUnregisterTviConnectDevice'],
    mutationFn: async (data: UnregisterTviConnectDeviceInputType[]) =>
      unregisterTviConnectDevice(data),
  })
}

export { useUnregisterTviConnectDevice }
