import styled from '@emotion/styled'
import { Input as AntdInput } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const SearchInput = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 320px;
  margin-bottom: 24px;
`

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  width: 100%;
  position: relative;
  user-select: none;
  margin: 24px auto;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`
