import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import {
  ImportTviConnectCheckDeviceInputType,
  ImportTviConnectCheckDeviceResponseType,
} from './types'
import { useMutation } from '@tanstack/react-query'
import message from '@/libs/message'

const importTviConnectCheckDevice = async (data: ImportTviConnectCheckDeviceInputType[]) => {
  const response = await httpClientPrivate.post<ImportTviConnectCheckDeviceResponseType[]>(
    `${config.restUrl}/v1/backoffice-ppu-motor/import-tvi-connect-check-devices`,
    { input: data },
  )

  return response.data
}

const useImportTviConnectCheckDevice = () => {
  return useMutation({
    mutationKey: ['useImportTviConnectCheckDevice'],
    mutationFn: async (data: ImportTviConnectCheckDeviceInputType[]) =>
      importTviConnectCheckDevice(data),
    onError: () => {
      message.warning('เกิดข้อผิดพลาด')
    },
  })
}

export { useImportTviConnectCheckDevice }
