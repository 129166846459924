import { useContext } from 'react'
import { withTheme } from '@emotion/react'
import { DownOutlined } from '@ant-design/icons'

import { AppBarInterface } from './interfaces'
import { Bar, Header, Logo, UserContainer, UserDropdown, UserText, HamburgerIcon } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { UserAuthContext } from '@/contexts'

function AppBar({ user, theme, setSideBar, isSideBarCollapse }: AppBarInterface) {
  const { logout } = useContext(UserAuthContext)

  const items = [
    {
      label: 'Logout',
      key: '1',
    },
  ]

  const handleMenuClick = (e: any) => {
    if (e.key === '1') {
      logout()
    }
  }

  return (
    <>
      <Header>Thaivivat Backoffice</Header>
      <Bar bgColor={theme.palette.primary.main}>
        <HamburgerIcon
          src={IMAGE_URL.hamburgerIcon}
          onClick={() => setSideBar(!isSideBarCollapse)}
        />
        <Logo src="/tvi-logo.png" />
        {user?.isAuth && (
          <UserContainer>
            <UserDropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']}>
              <UserText>
                <div>{`${user.firstname} ${user.lastname}`}</div>
                <DownOutlined />
              </UserText>
            </UserDropdown>
          </UserContainer>
        )}
      </Bar>
    </>
  )
}

export default withTheme(AppBar)
