import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { Container, Header, Title } from '@/pages/Help/styles'
import UnauthorizedPage from '@/pages/Unauthorized'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import ErrorLogTable from '@/components/feature/DopaPage/ErrorLogTable'

function Dopa() {
  const user = useRecoilValue(userAtom)

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.DOP010101], user.permissions) ? (
        <Container>
          <Header>
            <Title>DOPA</Title>
          </Header>
          <ErrorLogTable />
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default Dopa
