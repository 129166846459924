import { IMAGE_URL } from '@/constant/IMAGE'
import CheckTviConnectTable from '../CheckTviConnectTable'
import { TviConnectDeviceType } from '../types'
import {
  Badge,
  Bottom,
  Container,
  NbContainer,
  Mid,
  NumberBadge,
  SearchIcon,
  SearchInput,
  SubTitle,
  Tab,
  TabContainer,
  TContainer,
  Title,
  Top,
  MidContainer,
  NotFoundContainer,
  NotFoundIcon,
  NotFoundText,
  Icon,
  Skeleton,
} from './styles'
import CheckTviConnectSectionHook from './CheckTviConnectSection.hook'
import { FilterEnum } from './types'

const CheckTviConnectSection = ({
  devices,
  loading,
  setSearchDevice,
  searchDevices,
  removeDevice,
}: {
  setSearchDevice: (devices: TviConnectDeviceType[]) => void
  devices: TviConnectDeviceType[]
  loading: boolean
  searchDevices: TviConnectDeviceType[]
  removeDevice: (imei: string) => void
}) => {
  const { onSearch, onFilterData, selectedFilter } = CheckTviConnectSectionHook({
    devices,
    setSearchDevice,
  })
  const allDevicesCount = searchDevices?.length || 0
  const pendingDevicesCount = searchDevices?.filter((item) => !item.off || !item.on).length || 0
  const passedDevicesCount = searchDevices?.filter((item) => item.off && item.on).length || 0

  return (
    <Container>
      <Top>
        <NbContainer>
          <NumberBadge>2</NumberBadge>
        </NbContainer>
        <TContainer>
          <Title>ทดสอบอุปกรณ์</Title>
          <SubTitle>กรุณาเสียบ usb TVI Connect ที่ต้องการทดสอบ และดูสถานะ เปิดปิด</SubTitle>
        </TContainer>
      </Top>
      {devices.length > 0 ? (
        <>
          <Mid>
            <div style={{ marginRight: '16px', width: '18px' }} />
            <MidContainer>
              <TabContainer>
                <Tab
                  active={selectedFilter === FilterEnum.ALL}
                  onClick={() => onFilterData(FilterEnum.ALL)}
                >
                  ทั้งหมด
                  <Badge>{allDevicesCount}</Badge>
                </Tab>
                <Tab
                  active={selectedFilter === FilterEnum.PENDING}
                  onClick={() => onFilterData(FilterEnum.PENDING)}
                >
                  รอทดสอบ
                  <Badge>{pendingDevicesCount}</Badge>
                </Tab>
                <Tab
                  active={selectedFilter === FilterEnum.PASSED}
                  onClick={() => onFilterData(FilterEnum.PASSED)}
                >
                  ผ่าน
                  <Badge>{passedDevicesCount}</Badge>
                </Tab>
              </TabContainer>
              <SearchInput
                data-testid="check-tvi-connect-search-input"
                id="check-tvi-connect-search"
                placeholder="ค้นหา IMEI, Serial no."
                prefix={<SearchIcon />}
                onChange={onSearch}
              />
            </MidContainer>
          </Mid>
          <Bottom>
            <div style={{ marginRight: '16px', width: '18px' }} />
            <CheckTviConnectTable data={searchDevices} removeDevice={removeDevice} />
          </Bottom>
        </>
      ) : (
        <NotFoundContainer>
          <Skeleton active loading={loading}>
            <NotFoundIcon>
              <Icon src={IMAGE_URL.tviConnectIcon} />
            </NotFoundIcon>
            <NotFoundText>ไม่พบข้อมูล</NotFoundText>
          </Skeleton>
        </NotFoundContainer>
      )}
    </Container>
  )
}

export default CheckTviConnectSection
