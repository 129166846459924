import Table from '@/components/common/Table'
import CheckTviConnectTableHook from './CheckTviConnectTable.hook'
import { TviConnectDeviceType } from '../types'
import Modal from '@/components/common/Modal'

const CheckTviConnectTable = ({
  data,
  removeDevice,
}: {
  data: TviConnectDeviceType[]
  removeDevice: (imei: string) => void
}) => {
  const { columns, isModalOpen, setIsModalOpen, onRemoveDevice, selectedDevice } =
    CheckTviConnectTableHook({
      removeDevice,
    })
  return (
    <>
      <Table
        columns={columns}
        style={{ width: '100%', marginTop: '32px' }}
        dataSource={data}
        pagination={false}
      />
      <Modal
        title="คุณต้องการลบรายการนี้ใช่หรือไม่"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onRemoveDevice}
      >
        <div>ลบรายการ</div>
        <div>IMEI: {selectedDevice?.imei}</div>
        <div>Serial No.: {selectedDevice?.serialNumber}</div>
      </Modal>
    </>
  )
}

export default CheckTviConnectTable
