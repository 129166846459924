import AssignTviConnectInfo from './AssignTviConnectInfo'
import CheckTviConnectPageHook from './CheckTviConnectPage.hook'
import CheckTviConnectSection from './CheckTviConnectSection'
import ResetTviConnectSection from './ResetTviConnectSection'
import { Container } from './styles'

const CheckTviConnectPage = () => {
  const {
    devices,
    displayDevices,
    submitImportDevice,
    unregisterDevice,
    useImportDevice,
    setDisplayDevices,
    removeDevice,
  } = CheckTviConnectPageHook()

  return (
    <Container>
      <AssignTviConnectInfo submitImportDevice={submitImportDevice} />
      <CheckTviConnectSection
        devices={devices}
        searchDevices={displayDevices}
        loading={useImportDevice.isPending}
        setSearchDevice={setDisplayDevices}
        removeDevice={removeDevice}
      />
      <ResetTviConnectSection unregisterDevice={unregisterDevice} devices={devices} />
    </Container>
  )
}

export default CheckTviConnectPage
