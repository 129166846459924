export const IMAGE_URL = {
  plusIcon: '/assets/icons/plus-icon.svg',
  editIcon: '/assets/icons/edit-icon.svg',
  tableTrashIcon: '/assets/icons/table-trash-icon.svg',
  AHExercisePoint: '/assets/icons/campaign/ah-exercise-point.svg',
  AHExercisePoint1: '/assets/icons/campaign/ah-exercise-point1.svg',
  boostPointIcon: '/assets/icons/campaign/boost-point-icon.svg',
  deleteIcon: '/assets/icons/delete-icon.svg',
  editGreyIcon: '/assets/icons/edit-grey-icon.svg',
  deleteGreyIcon: '/assets/icons/delete-grey-icon.svg',
  addTeamMemberIcon: '/assets/icons/add-team-member-icon.svg',
  userNotFoundIcon: '/assets/icons/user-not-found-icon.svg',
  userIcon: '/assets/icons/user-icon.svg',
  addUserIcon: '/assets/icons/add-user-icon.svg',
  closeIcon: '/assets/icons/close-icon.svg',
  closeBlackIcon: '/assets/icons/close-black-icon.svg',
  shareIcon: '/assets/icons/share-icon.svg',
  hamburgerGreyIcon: '/assets/icons/hamburger-grey-icon.svg',
  chevronRightIcon: '/assets/icons/chevron-right-icon.svg',
  importIcon: '/assets/icons/import-icon.svg',
  chevronUpIcon: '/assets/icons/chevron-up-icon.svg',
  chevronDownIcon: '/assets/icons/chevron-down-icon.svg',
  exportIcon: '/assets/icons/export-icon.svg',
  trashIcon: '/assets/icons/trash-icon.svg',
  uploadGreyIcon: '/assets/icons/upload-grey-icon.svg',
  flashIcon: '/assets/icons/flash-icon.svg',
  billingSummaryIcon: '/assets/icons/billing-summary-icon.svg',
  pdfIcon: '/assets/icons/pdf-icon.png',
  tableFilterIcon: '/assets/icons/table-filter-icon.svg',
  visaIcon: '/assets/icons/visa-icon.svg',
  amexIcon: '/assets/icons/amex-icon.svg',
  dinerIcon: '/assets/icons/diner-icon.svg',
  jcbIcon: '/assets/icons/jcb-icon.svg',
  mastercardIcon: '/assets/icons/mastercard-icon.svg',
  blueFlagIcon: '/assets/icons/blue-flag-icon.svg',
  pointIcon: '/assets/icons/point-icon.svg',
  calendarIcon: '/assets/icons/calendar-icon.svg',
  eyeIcon: '/assets/icons/eye-icon.svg',
  searchIcon: '/assets/icons/search-icon.svg',
  notFoundIcon: '/assets/icons/not-found-icon.svg',
  stepIcon: '/assets/icons/steps-icon.svg',
  clokcwatchIcon: '/assets/icons/clockwatch-icon.svg',
  successResetPasswordIcon: '/assets/icons/successfully-reset-password-icon.svg',
  forgotPasswordIcon: '/assets/icons/forget-password-icon.svg',
  hamburgerIcon: '/assets/icons/hamburger-icon.svg',
  simIcon: '/assets/icons/sim-icon.svg',
  resetDeviceIcon: '/assets/icons/reset-device-icon.svg',
  unboundDeviceIcon: '/assets/icons/unbound-device-icon.svg',
  uploadIcon: '/assets/icons/upload-icon.svg',
  failIcon: '/assets/icons/fail-icon.svg',
  motorIcon: '/assets/icons/motor-icon.svg',
  activeHealthIcon: '/assets/icons/active-health-icon.svg',
  travelIcon: '/assets/icons/travel-icon.svg',
  travelMenuIcon: '/assets/icons/travel-menu-icon.svg',
  sendMailIcon: '/assets/icons/send-mail-icon.svg',
  copyLinkIcon: '/assets/icons/copy-link-icon.svg',
  fireIcon: '/assets/icons/fire-icon.svg',
  wellBeingIcon: '/assets/icons/well-being-icon.svg',
  qrIcon: '/assets/icons/qr-icon.svg',
  wellBeingOutlinedIcon: '/assets/icons/well-being-outlined-icon.svg',
  helpIcon: '/assets/icons/help-icon.svg',
  campaignManagementIcon: '/assets/icons/campaign-management-icon.svg',
  watchManagementIcon: '/assets/icons/watch-management-icon.svg',
  discountIssueIcon: '/assets/icons/discount-issues-icon.svg',
  policyManagementIcon: '/assets/icons/policy-management-icon.svg',
  welcomeCallIcon: '/assets/icons/welcome-call-icon.svg',
  motorTrackerIcon: '/assets/icons/motor-tracker-icon.svg',
  tviConnectIcon: '/assets/icons/tvi-connect-management-icon.svg',
  userManagementIcon: '/assets/icons/user-management-icon.svg',
  rolesManagementIcon: '/assets/icons/roles-management-icon.svg',
  reportIcon: '/assets/icons/report-icon.svg',
  faqIcon: '/assets/icons/frequency-question-icon.svg',
  evChargingStationIcon: '/assets/icons/ev-station-icon.svg',
  evChargingStationMarker: '/assets/icons/ev-station-marker.svg',
  evChargingStationSelectedMarker: '/assets/icons/ev-station-marker-selected.svg',
  evPlugIcon: '/assets/icons/ev-plug-icon.svg',
  keyIcon: '/assets/icons/key-icon.svg',
  loginLogoIcon: '/assets/icons/login-icon.svg',
  backgroundSsoPicture: '/assets/pictures/background-sso.svg',
  locationIcon: '/assets/icons/location-icon.svg',
  returnTimeIcon: '/assets/icons/return-time-icon.svg',
  personCardIcon: '/assets/icons/person-card-icon.svg',
  checkTviConnectIcon: '/assets/icons/check-tvi-connect-icon.svg',
}
