import { useEffect, useState } from 'react'
import { useErrorLogPagination } from '@/services/dopa/useErrorLogPagination'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { toThaiLocaleDateDash, toThaiLocaleDateDashAndTime } from '@/libs/date'
import { SorterResult, SortOrder } from 'antd/lib/table/interface'
import { SorterEnum } from '@/constant/USER'
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs'

const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

export default function ErrorLogTableHook() {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)

  const handlePaginate = (page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }
  const mapDopaErrorLogFrom = (value: string): string => {
    if (value === 'super-app-register') return 'ลงทะเบียน'
    if (value === 'super-app-buy-pa-active-shield') return 'ซื้อประกัน PA Active shield'
    if (value === 'super-app-buy-cancer') return 'ซื้อประกัน Cancer'
    return 'n/a'
  }
  const errorLog = useErrorLogPagination({ page, limit, search, sortBy })
  const columns: ColumnsType<{}> = [
    { title: 'ลำดับ', width: 75, align: 'center', render: (value, record, index) => index + 1 },
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'registerDate',
      render: (value: string) => toThaiLocaleDateDashAndTime(value),
    },
    {
      title: 'ลงทะเบียนจาก',
      dataIndex: 'from',
      render: mapDopaErrorLogFrom,
    },
    { title: 'เลขบัตรประจำตัวประชาชน', dataIndex: 'identityId' },
    { title: 'REQUEST ID', dataIndex: 'requestId' },
    { title: 'ชื่อผู้เอาประกันที่กรอก', dataIndex: 'firstName' },
    { title: 'นามสกุลผู้เอาประกันที่กรอก', dataIndex: 'lastName' },
    {
      title: 'วันเดือนปีเกิดที่กรอก',
      dataIndex: 'registerDateOfBirth',
      render: (value: string) => toThaiLocaleDateDash(value),
    },
    { title: 'ERROR MESSAGE', dataIndex: 'errorDescription' },
  ]
  const handleSortBy = (field?: React.Key | readonly React.Key[], order?: SortOrder) => {
    switch (order) {
      case SorterEnum.ASC:
        return `${field}:ASC`
      case SorterEnum.DESC:
        return `${field}:DESC`
      default:
        return ''
    }
  }
  const onTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<{}> | SorterResult<{}>[],
  ) => {
    const { current, pageSize } = pagination
    const sort = Array.isArray(sorter) ? sorter[0] : sorter
    const { field, order } = sort || { field: '', order: '' }
    const sortBy = handleSortBy(field, order)

    setPage(current || 1)
    setLimit(pageSize || 10)
    setSortBy(sortBy)
  }
  useEffect(() => {
    const subscription = inputChange$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        setSearch(value)
        setPage(1)
      })

    return () => {
      inputChange.next('')
      return subscription.unsubscribe()
    }
  }, [])
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    inputChange.next(value)
  }

  return { errorLog, columns, onTableChange, onSearch, handlePaginate }
}
