import styled from '@emotion/styled'
import { CheckCircleFilled } from '@ant-design/icons'

export const DeleteIcon = styled('img')`
  height: 20px;
  width: 20px;
  cursor: pointer;
`

export const CheckIcon = styled(CheckCircleFilled)<{ isGreen: boolean }>`
  color: ${(props) => (props.isGreen ? '#009640' : '#d6d6d6')};
`
