import { ReactElement, useMemo } from 'react'
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'

import { acquireToken } from '@/providers/msal.helper'
import { httpClientPrivate } from '@/libs/httpClient'
import { msalInstance } from '@/providers/msal'
import CONSTANT from '@/constant'

interface ProviderInterface {
  children: ReactElement
}

type AxiosErrorRetry = {
  config: {
    _retry: boolean
  }
}

export const AxiosProvider = ({ children }: ProviderInterface) => {
  const account = msalInstance.getActiveAccount()

  useMemo(() => {
    const handle401Error = async (error: AxiosError & AxiosErrorRetry): Promise<any> => {
      const errorStatus = error.response?.status
      const originalRequest = error.config

      if (errorStatus === 401 && account && originalRequest) {
        if (!originalRequest._retry) {
          originalRequest._retry = true // prevent infinite retry loops

          const loginRequest = { scopes: ['openid', 'User.Read'], account }

          try {
            const idToken = await acquireToken(loginRequest)
            if (originalRequest.headers && idToken) {
              originalRequest.headers.Authorization = `Bearer ${idToken}`
              return axios(originalRequest) // retry original request with new token
            }
          } catch (authError) {
            return Promise.reject(authError) // handle token refresh failure
          }
        }
        localStorage.clear()
        msalInstance.clearCache()
        window.location.pathname = CONSTANT.ROUTES.LOGIN_PAGE
      } else if (!account) {
        window.location.reload()
      }

      return Promise.reject(error) // return the original error if no other action is taken
    }

    const setAuthorizationHeader = async (
      config: InternalAxiosRequestConfig<any>,
    ): Promise<InternalAxiosRequestConfig<any>> => {
      if (config.headers && !config.headers.Authorization && account) {
        let token = localStorage.getItem('accessToken')

        if (!token) {
          const loginRequest = { scopes: ['openid', 'User.Read'], account }

          try {
            token = await acquireToken(loginRequest)
          } catch (authError) {
            return Promise.reject(authError) // handle token acquisition failure
          }
        }

        config.headers.setAuthorization(token ? `Bearer ${token}` : '')
      }

      return config
    }

    // setup interceptors
    httpClientPrivate.interceptors.response.use((response) => response, handle401Error)
    httpClientPrivate.interceptors.request.use(setAuthorizationHeader)

    // clean interceptors
    return () => {
      httpClientPrivate.interceptors.response.clear()
      httpClientPrivate.interceptors.request.clear()
    }
  }, [account])

  return children
}
