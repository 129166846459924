import styled from '@emotion/styled'
import { Button } from 'antd'

export const Container = styled('div')`
  border-top: 1px solid #e5e5e5;
`

export const Top = styled('div')`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const Bottom = styled('div')`
  display: flex;
`

export const NbContainer = styled('div')`
  border-radius: 50%;
  background-color: #e6f6ea;
  width: 28px;
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const NumberBadge = styled('div')`
  color: #009640;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
`

export const TContainer = styled('div')``

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  color: #000000;
`

export const SubTitle = styled('div')`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`

export const GreenButton = styled(Button)`
  background-color: #009640;
  border-color: #009640;
  color: white;

  &:focus {
    background-color: #007a30;
    border-color: #007a30;
  }
`
