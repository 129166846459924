import { httpClientPrivate } from '@/libs/httpClient'
import { useQuery } from '@tanstack/react-query'
import config from '@/config'
import { ErrorLogPaginationResponse } from '@/services/dopa/useErrorLogPagination/types'

const fetchErrorLogPagination = async (
  page: number,
  limit: number,
  search: string,
  sortBy: string,
) => {
  const url = `${config.restUrl}/v1/backoffice-dopa/dopa-error-log?page=${page}&limit=${limit}&search=${search}&sort=${sortBy}`
  const response = await httpClientPrivate.get<ErrorLogPaginationResponse>(url)
  return response.data
}

const useErrorLogPagination = (query: {
  page: number
  limit: number
  search: string
  sortBy: string
}) => {
  const queryKey = ['useErrorLogPagination', query]
  const queryFn = async () =>
    fetchErrorLogPagination(query.page, query.limit, query.search, query.sortBy)
  const queryResult = useQuery({
    enabled: query.page && query.limit ? true : false,
    queryKey,
    queryFn,
  })
  return { ...queryResult }
}

export { useErrorLogPagination, fetchErrorLogPagination }
