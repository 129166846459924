import dayjs from 'dayjs'
import { Container, SearchIcon, SearchInput, LastSyncText } from './styles'
import ActiveHealthPolicyManagementTableHook from './ActiveHealthPolicyManagementTable.hook'
import Table from '@/components/common/Table'

function ActiveHealthPolicyManagementTable() {
  const {
    policies,
    pagination,
    currentPage,
    currentPageSize,
    handleOnChangeTable,
    handleOnSearchInputChange,
    getColumns,
    activeHealthPolicyLoading,
  } = ActiveHealthPolicyManagementTableHook()

  return (
    <Container>
      <SearchInput
        id="active-health-policy-search"
        placeholder="ค้นหา..."
        prefix={<SearchIcon />}
        onChange={() => handleOnSearchInputChange(true)}
        data-testid="policy-management-search-input"
      />
      <LastSyncText>ดึงข้อมูลล่าสุด {dayjs().format('DD-MM-YYYY HH:mm:ss')}</LastSyncText>
      <Table
        data-testid="policy-management-table"
        loading={activeHealthPolicyLoading}
        columns={getColumns().filter((column) => column.width !== 0)}
        dataSource={policies}
        scroll={{
          x: '230vw',
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination?.totalItems,
          defaultCurrent: 1,
          current: currentPage,
          defaultPageSize: 10,
          pageSize: currentPageSize,
        }}
        rowKey="index"
        onChange={(change) => handleOnChangeTable(change)}
        totalRecord={pagination?.totalItems}
      />
    </Container>
  )
}

export default ActiveHealthPolicyManagementTable
