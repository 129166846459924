import CheckTviConnectPage from '@/components/feature/CheckTviConnectPage'
import { Container, Title } from './styles'

const CheckTviConnect = () => {
  return (
    <Container>
      <Title>ตรวจสอบอุปกรณ์ TVI Connect</Title>
      <CheckTviConnectPage />
    </Container>
  )
}

export default CheckTviConnect
