import { Radio, Space, Upload } from 'antd'
import {
  Container,
  Title,
  Top,
  NbContainer,
  NumberBadge,
  SubTitle,
  TContainer,
  Bottom,
  GreenButton,
  Field,
  FieldContainer,
  OptionTitle,
  RadioGroup,
} from './styles'
import AssignTviConnectInfoHook from './AssignTviCOnnectInfo.hook'
import { ImportTviConnectCheckDeviceInputType } from '@/services/tviConnect/useImportTviConnectCheckDevice/types'

const AssignTviConnectInfo = ({
  submitImportDevice,
}: {
  submitImportDevice: (data: ImportTviConnectCheckDeviceInputType[]) => Promise<void>
}) => {
  const {
    option,
    setOption,
    UPLOAD,
    MANUAL,
    uploadProps,
    setImeiInput,
    setSerialNumberInput,
    imeiInput,
    serialNumberInput,
    submitInputDevice,
  } = AssignTviConnectInfoHook({ submitImportDevice })

  return (
    <Container>
      <Top>
        <NbContainer>
          <NumberBadge>1</NumberBadge>
        </NbContainer>
        <TContainer>
          <Title>ระบุอุปกรณ์</Title>
          <SubTitle>
            ระบบจะทำการ Unregister หลังจากนั้นจะ Register และเปลี่ยน Main package เป็น package
            ที่ใช้งานได้ (สามารถเพิ่มรายการรวมทั้งหมดไม่เกิน 50 อุปกรณ์ต่อครั้ง)
          </SubTitle>
        </TContainer>
      </Top>
      <Bottom>
        <div style={{ marginRight: '16px', width: '18px' }} />
        <RadioGroup value={option} onChange={(e) => setOption(e.target.value)}>
          <Space direction="vertical">
            <Radio value={UPLOAD}>
              <OptionTitle>
                อัพโหลดข้อมูล TVI Connect หลายรายการ{' '}
                <a
                  style={{ color: '#009400' }}
                  href="/assets/templates/csv/import-tvi-connect-template.csv"
                  download="import-tvi-connect-template.csv"
                >
                  ดาวน์โหลด Template
                </a>{' '}
                รองรับไฟล์ .csv เท่านั้น
              </OptionTitle>
              <Upload accept=".csv" {...uploadProps} showUploadList={false}>
                <GreenButton disabled={option !== UPLOAD}>อัพโหลด</GreenButton>
              </Upload>
            </Radio>
            <Radio value={MANUAL}>
              <OptionTitle>ระบุที่ TVI Connect ทีละอุปกรณ์</OptionTitle>
              <FieldContainer>
                <Field
                  placeholder="Imei"
                  disabled={option !== MANUAL}
                  value={imeiInput}
                  onChange={(e) => setImeiInput(e.target.value)}
                />
                <Field
                  placeholder="Serial Number"
                  disabled={option !== MANUAL}
                  value={serialNumberInput}
                  onChange={(e) => setSerialNumberInput(e.target.value)}
                />
                <GreenButton
                  disabled={option !== MANUAL || !imeiInput || !serialNumberInput}
                  onClick={submitInputDevice}
                >
                  เพิ่มอุปกรณ์
                </GreenButton>
              </FieldContainer>
            </Radio>
          </Space>
        </RadioGroup>
      </Bottom>
    </Container>
  )
}

export default AssignTviConnectInfo
