import { ReactElement, useEffect } from 'react'
import { MsalProvider as Provider } from '@azure/msal-react'
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'
import config from '@/config'

interface ProviderInterface {
  children: ReactElement
}

export const msalInstance = new PublicClientApplication(config.msalConfig)

export const MsalProvider = ({ children }: ProviderInterface) => {
  // Function to save the idToken to localStorage
  const saveIdToken = (idToken: string) => {
    localStorage.setItem('accessToken', idToken)
  }

  // Function to remove the idToken to localStorage
  const removeIdToken = () => {
    localStorage.removeItem('accessToken')
  }

  // Function to handle successful token acquisition
  const handleTokenSuccess = (payload: AuthenticationResult) => {
    const { account, idToken } = payload
    msalInstance.setActiveAccount(account)
    saveIdToken(idToken)
  }

  // Extracted event callback function
  const eventCallback = (event: EventMessage) => {
    switch (event.eventType) {
      case EventType.LOGIN_SUCCESS:
        if (event.payload) {
          handleTokenSuccess(event.payload as AuthenticationResult)
        }
        break

      case EventType.ACQUIRE_TOKEN_SUCCESS:
        if (event.payload) {
          saveIdToken((event.payload as AuthenticationResult).idToken)
        }
        break

      case EventType.SSO_SILENT_SUCCESS:
        if (event.payload) {
          handleTokenSuccess(event.payload as AuthenticationResult)
        }
        break

      case EventType.LOGOUT_SUCCESS:
        removeIdToken()
        break

      default:
        break
    }
  }

  // Extracted function to set the active account
  const setActiveAccount = (accounts: AccountInfo[]) => {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
    }
  }

  useEffect(() => {
    // Set the initial active account if available
    const accounts = msalInstance.getAllAccounts()
    setActiveAccount(accounts)

    // Register event callback for MSAL events
    const callbackId = msalInstance.addEventCallback(eventCallback)

    // Clean up the event callback when the component unmounts
    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Provider instance={msalInstance}>{children}</Provider>
}
