import { useEffect } from 'react'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import CONSTANT from './constant'
import {
  ForgotPassword,
  Blank,
  Login,
  PolicyDetail,
  Report,
  ResetPassword,
  TviConnect,
  WelcomeCall,
  PolicyManagement,
  DiscountIssue,
  WatchManagement,
  CampaignManagement,
  Help,
  FrequencyQuestions,
  UserPermission,
  WellBeing,
  UserNotFound,
  EvChargingStation,
  TaTransaction,
  TaTransactionOrder,
} from './pages'
import { BACKOFFICE_USER_PAGE } from '@/constant/BACKOFFICE_PERMISSION'
import RequireAuth from '@/RequireAuth'
import userAtom from '@/recoil/user'
import { useRecoilValue } from 'recoil'
import delay from '@/libs/delay'
import { PARAMS } from './constant/ROUTES'
import ReturnTime from './pages/ReturnTime'
import CheckTviConnect from './pages/CheckTviConnect'
import Dopa from '@/pages/Dopa/Dopa'

export type RouteTypes = {
  path: string
  element: () => EmotionJSX.Element
  isAuth: boolean
  hasChildren: boolean
  pageCode?: Array<string>
  children: { path?: string; pageCode?: Array<string>; element?: () => EmotionJSX.Element }
}

export default function RoutesHook() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useRecoilValue(userAtom)
  const routes: Array<RouteTypes> = [
    {
      path: CONSTANT.PATHNAME.STAR,
      element: Blank,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.PATHNAME.LOGIN,
      element: Login,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.PASSWORD_FORGOT_PAGE,
      element: ForgotPassword,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.PASSWORD_RESET_PAGE,
      element: ResetPassword,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.CONFIRM_EMAIL_PAGE,
      element: ForgotPassword,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.TVI_CONNECT_PAGE,
      element: TviConnect,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.MTD0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.EV_CHARGING_STATION_PAGE,
      element: EvChargingStation,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.MTD0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.PATHNAME.POLICY_DETAIL,
      element: PolicyDetail,
      isAuth: true,
      hasChildren: true,
      pageCode: [BACKOFFICE_USER_PAGE.CUS0101, BACKOFFICE_USER_PAGE.MTP0101],
      children: {
        path: CONSTANT.PARAMS.SEARCH_INPUT,
        element: PolicyDetail,
      },
    },
    {
      path: CONSTANT.ROUTES.REPORT_PAGE,
      element: Report,
      isAuth: true,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.WELCOME_CALL_PAGE,
      element: WelcomeCall,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.WEL0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE,
      element: PolicyManagement,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.POL0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE,
      element: DiscountIssue,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.DIS0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.WATCH_MANAGEMENT_PAGE,
      element: WatchManagement,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.WAT0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.CAMPAIGN_MANAGEMENT_PAGE,
      element: CampaignManagement,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.CAM0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.HELP_PAGE,
      element: Help,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.HEL0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.FREQUENCY_QUESTIONS_PAGE,
      element: FrequencyQuestions,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.FAQ0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.USER_PERMISSION_PAGE,
      element: UserPermission,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.USR0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.PATHNAME.WELL_BEING,
      element: WellBeing,
      isAuth: true,
      hasChildren: true,
      pageCode: [BACKOFFICE_USER_PAGE.WBE0101],
      children: {
        path: PARAMS.PARTNER_ID,
        element: WellBeing,
      },
    },
    {
      path: CONSTANT.PATHNAME.TA_TRANSACTION,
      element: TaTransaction,
      isAuth: true,
      hasChildren: true,
      pageCode: [BACKOFFICE_USER_PAGE.TA0101],
      children: {
        path: PARAMS.ORDER_ID,
        pageCode: [BACKOFFICE_USER_PAGE.TA0102],
        element: TaTransactionOrder,
      },
    },
    {
      path: CONSTANT.PATHNAME.RETURN_TIME,
      element: ReturnTime,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.MTA0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.TVI_CONNECT_CHECK_PAGE,
      element: CheckTviConnect,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.MTD0101], // TODO: add permission for this page
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.PATHNAME.DOPA,
      element: Dopa,
      isAuth: true,
      hasChildren: false,
      pageCode: [BACKOFFICE_USER_PAGE.DOP0101],
      children: {
        path: undefined,
        element: undefined,
      },
    },
    {
      path: CONSTANT.ROUTES.USER_NOT_FOUND_PAGE,
      element: UserNotFound,
      isAuth: false,
      hasChildren: false,
      pageCode: undefined,
      children: {
        path: undefined,
        element: undefined,
      },
    },
  ]

  const routeComponents = routes.map(
    (
      {
        path,
        element: Component,
        hasChildren,
        isAuth,
        pageCode,
        children: { path: childrenPath, pageCode: childrenPageCode, element: ChildrenComponent },
      },
      key,
    ) =>
      isAuth ? (
        <Route
          path={path}
          element={
            <RequireAuth pageCode={pageCode}>
              <Component />
            </RequireAuth>
          }
          key={key}
        >
          {hasChildren && childrenPath && ChildrenComponent ? (
            <Route
              path={childrenPath}
              element={
                <RequireAuth pageCode={childrenPageCode ? childrenPageCode : pageCode}>
                  <ChildrenComponent />
                </RequireAuth>
              }
            />
          ) : undefined}
        </Route>
      ) : (
        <Route path={path} element={<Component />} key={key} />
      ),
  )

  const navigateToDefaultPage = () => {
    const firstPage = user.pages[0]
    const route = routes.filter((route) => route?.pageCode?.includes(firstPage))[0]

    return navigate(route.path)
  }

  useEffect(() => {
    // redirect to default page after logged in and have page permission
    if (user.isAuth && user.pages.length > 0) {
      delay(50).then(() => navigateToDefaultPage())
    }

    // for user who's logged in but have not authorized
    if (user.isAuth && !user.pages.length) {
      delay(50).then(() => navigate(CONSTANT.ROUTES.UNAUTHORIZED_PAGE))
    }

    // for anonymous who's reach any page
    if (!user.isAuth && !user.pages.length) {
      // check not required authenticate page
      if (location.pathname) {
        const path = location.pathname.split('/')[1]
        const isPublicPage =
          routes.filter((r) => r.path.split('/')[1] === path && !r.isAuth).length > 0

        // if found not required authenticate page allow to reach
        if (isPublicPage) {
          delay(50).then(() => navigate(location.pathname))
        } else {
          delay(50).then(() => navigate(CONSTANT.ROUTES.LOGIN_PAGE))
        }
      }
    }
  }, [user.pages, user.isAuth])

  return { routeComponents }
}
