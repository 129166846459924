import { InteractionRequiredAuthError, PopupRequest } from '@azure/msal-browser'
import { msalInstance } from '@/providers/msal'

export const acquireToken = async (loginRequest: PopupRequest): Promise<string | null> => {
  // check if running in an iframe or nested popup
  if (window.opener != null || window.self !== window.top) {
    return null
  }

  try {
    return await acquireTokenSilent(loginRequest)
  } catch (error) {
    return handleTokenAcquisitionError(error, loginRequest)
  }
}

// attempt to acquire token silently
const acquireTokenSilent = async (loginRequest: PopupRequest): Promise<string> => {
  const tokenSilent = await msalInstance.acquireTokenSilent(loginRequest)
  return tokenSilent.idToken
}

// acquire token using a popup
const acquireTokenWithPopup = async (loginRequest: PopupRequest): Promise<string> => {
  const response = await msalInstance.acquireTokenPopup({
    ...loginRequest,
    redirectUri: '/blank.html',
  })
  return response.idToken
}

const attemptTokenAcquisitionWithPopup = async (request: PopupRequest): Promise<string | null> => {
  try {
    return await acquireTokenWithPopup(request)
  } catch (popupError) {
    return null
  }
}

// handle errors during token acquisition
const handleTokenAcquisitionError = async (
  error: any,
  loginRequest: PopupRequest,
): Promise<string | null> => {
  if (error instanceof InteractionRequiredAuthError) {
    //if there are claims, modify the login request
    if (error.claims) {
      loginRequest.claims = error.claims
    }

    return await attemptTokenAcquisitionWithPopup(loginRequest)
  } else if (error.errorCode === 'monitor_window_timeout') {
    return await attemptTokenAcquisitionWithPopup(loginRequest)
  }

  throw error
}
