import { useState } from 'react'

const ResetTviConnectSectionHook = ({ unregisterDevice }: { unregisterDevice: () => void }) => {
  const [openModal, setOpenModal] = useState(false)

  const onUnregisterDevice = () => {
    unregisterDevice()
    setOpenModal(false)
  }

  return { openModal, setOpenModal, onUnregisterDevice }
}

export default ResetTviConnectSectionHook
